<template>
  <div style="text-align: left;" v-if="state.ready">
    <v-container v-if="meeting">
        <v-row>
            <v-col>
                <v-btn text @click="exit">
                    <v-icon>
                        mdi-chevron-left
                    </v-icon> Back
                </v-btn>                
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <template v-if="allowSurveySwitch">
                    <v-chip v-for="(survey, index) in surveyItems" :key="`survey-select-${index}`" :color="surveyIndex==index ? 'green' : 'blue'" dark :disabled="survey.disabled" @click="selectSurvey(survey, index)">
                        {{survey.text}}
                    </v-chip>
                </template>
                <template v-if="questions_id">
                    <QuestionSet v-if="ready" :questionSetID="questions_id" v-model="questionSet" :requestDebug="$store.getters.debug" @submit="submitSurvey"/>
                    <h3 v-else>...loading...</h3>
                </template>
                <template v-else>
                    <h3 v-if="!surveyItems.length && !survey_stage">...Loading...</h3>
                    <h3 v-else>Survey has not been released</h3>
                </template>
            </v-col>
        </v-row>
    </v-container>

    <v-container v-else>
        <v-row>
            <v-col>
                <center>Invalid premeeting url</center>
            </v-col>
        </v-row>        
    </v-container>
    
  </div>
</template>

<script>
import MeetingSystem from '@/components/Event/eventSystem.js'
import QuestionSet from '@/components/Questions/QuestionSet'
export default {
    created: async function(){
        this.meetingsAPI = new MeetingSystem(this)
        if(this.meetings==undefined){
            let meetings = await this.meetingsAPI.getMeetings()
            this.$store.commit('meetings',meetings)            
        }

        if(this.$route.name=='PreMeetingSurvey'){
            if(this.meeting && this.meeting.preMeetingSurvey){
                this.questionSetId = this.meeting.preMeetingSurvey
            }
            if(this.completedPreMeetingSurvey){
                this.exit()
            }
        }


        if(this.$route.name=='PostMeetingSurvey'){
            if(this.meeting && this.meeting.postMeetingSurvey){
                this.questionSetId = this.meeting.postMeetingSurvey
            }
            if(this.completedPostMeetingSurvey){
                this.exit()
            }
        }        
        this.state.ready = true
    },
    components: {
        QuestionSet
    },
    data: function(){
        return {
            state: {
                ready: false
            },
            ready: false,
            surveyIndex: null,
            meetingsAPI: null,
            questionSetId: null,
            questions_id: undefined,
            survey_stage: null,
            questionSetData: null,
            questionsData: null,
            questionSet: null,
        }
    },
    methods: {
        exit: function(){
            this.$router.push({name:'EventDetails',params:{event_id:this.meeting.id}})
        },
        getQuestionSet: async function(questionSetId){
            this.ready = false
            let response = await this.sendRequest('get',`/api/question_set/${questionSetId}`)
            if(response.status==200){
                this.questionSetData = response.data[0]
                if(this.surveyItems[2].value){
                    // this.questions_id = this.questionSetData.prod_id
                    this.selectSurvey(this.surveyItems[2],2)
                }
            }
        },
        getQuestions: async function(questions_id){
            this.ready = false
            let response = await this.sendRequest('get',`/api/questions/${questions_id}`)
            if(response.status==200){
                this.questionsData = response.data.questions[0]
                this.questionSet = {
                    hub: {
                        logicIndex: [],
                        logs: []
                    },
                    logics: response.data.logics[0].data,
                    prompts: response.data.prompts[0].data,
                    questions: response.data.questions[0].data
                }
                this.ready = true
            }
        },
        selectSurvey: function(survey, index){
            this.questions_id = survey.value
            this.survey_stage = survey.stage
            this.surveyIndex = index
        },
        submitSurvey: async function(surveyData){
            let data = {
                survey_type: this.$route.name,
                user_id: this.$store.getters.user.username,
                event_id: this.event_id,
                question_set_id: this.questionSetId,
                questions_id: this.questions_id,
                version: this.questionsData.version,
                survey_stage: this.survey_stage,
                data: surveyData
            }
            let response = await this.sendRequest('post','/api/meetings/survey',data)
            if(response.status==200){
                await this.meetingsAPI.getMeetings()
                this.exit()
            }
        }
    },
    computed: {
        allowSurveySwitch: function(){
            let rights = this.$store.getters.rights
            let allowed = ['SuperAdmin','admin','staff','testers']
            for(let i=0; i<rights.length; i++){
                let group = rights[i]
                if(allowed.includes(group)){
                    return true
                }
            }
            return false
        },
        meetingMap: function(){
            let map = {}
            let meetings = this.$store.getters.meetings
            if(meetings){
                for(let i=0; i<meetings.length; i++){
                    let meeting = meetings[i]
                    map[meeting.id] = meeting
                }
            }

            return map
        },
        event_id: function(){
            return this.$route.params.event_id
        },
        meeting: function(){
            let meetingMap = this.meetingMap
            let event_id = this.event_id
            return meetingMap[event_id] ? meetingMap[event_id] : undefined
        },
        surveyItems: function(){
            let questionSetData = this.questionSetData
            let output = []
            if(questionSetData){
                output = [                
                    {text: 'Dev', stage: 'dev', value: questionSetData.dev_id, disabled: questionSetData.dev_id ? false : true},
                    {text: 'QA', stage: 'qa', value: questionSetData.qa_id, disabled: questionSetData.qa_id ? false : true},
                    {text: 'Prod', stage: 'prod', value: questionSetData.prod_id, disabled: questionSetData.prod_id ? false : true},
                ]                
            }

            return output
        },
        completedPreMeetingSurvey: function(){
            let meeting = this.meeting
            let preMeetingAnswers = this.$store.getters.preMeetingAnswers
            let completed = preMeetingAnswers.filter((submission)=>{return submission.question_set_id==meeting.preMeetingSurvey && submission.event_id==meeting.id}).length>0
            return completed
        },  
        completedPostMeetingSurvey: function(){
            let meeting = this.meeting
            let postMeetingAnswers = this.$store.getters.postMeetingAnswers
            let completed = postMeetingAnswers.filter((submission)=>{return submission.question_set_id==meeting.postMeetingSurvey && submission.event_id==meeting.id}).length>0
            return completed
        },        
    },
    watch: {
        questionSetId: function() {
            this.getQuestionSet(this.questionSetId)
        },
        questions_id: function(){
            this.getQuestions(this.questions_id)
        },
        completedPreMeetingSurvey: function(){
            if(this.completedPreMeetingSurvey){
                this.exit()
            }
        },
        completedPostMeetingSurvey: function(){
            if(this.completedPostMeetingSurvey){
                this.exit()
            }
        },
        state: {
            deep: true,
            handler: function(){
                let ready = this.state.ready
                let meeting = this.meeting
                if(ready && !meeting){
                    let self = this
                    setTimeout(()=>{self.$router.push({name:'Home'})},2000)
                }
            }
        }
    }

}
</script>

<style>

</style>